import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';


const PrivateRoute = ({ component: Component, auth, ...rest }) => {
   // If authenticated return an outlet that will render child elements
   // If not authenticated, return element that will navigate to login page
   if (auth.isAuthenticated && auth.user) {
      return <Outlet />
   } else {
      // Right now the only two auth guarded paths in our app are 
      // '/' and '/data-services' - if this expands we likely want to find 
      // a proper/more robust solution for post login redirects
      if (window.location.href.endsWith('data-services')) {
         localStorage.setItem('login_redirect', '/data-services');
      }
      return <Navigate to="/login" />
   }
}

const mapStateToProps = (state) => ({
   auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);