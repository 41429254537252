import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import InfraMarkerService from '../../api/infraMarkerService';
import { IAppState } from '../../reducers';
import { IDwToken } from '../../api/models';
import { IOrg } from '../../reducers/organizations';
import { MultiSelect, Option } from 'react-multi-select-component';
import infraMarkerService from '../../api/infraMarkerService';

interface IDataKeyParams {
   orgs: IOrg[] | null,
   userName: string | undefined,
   tokens: IDwToken[],
   addToken: (newRec: IDwToken) => void,
   setLoading: (isLoading: boolean) => void
}

export const DataKey: React.FC<IDataKeyParams> = (param: IDataKeyParams) => {
   const store = useSelector<any, IAppState>(store => store);

   const today = new Date();
   today.setHours(0, 0, 0, 0);
   const maxDate = new Date(today.setMonth(today.getMonth() + 6));

   const [tokenGenerateError, setTokenGenerateError] = useState("");
   const [organization, setOrganization] = useState("");
   const [orgHasGeoFilter, setOrgHasGeoFilter] = useState(false);
   const [tokenName, setTokenName] = useState("");
   const [tokenNameError, setTokenNameError] = useState("");
   const [tokenCountError, setTokenCountError] = useState("");
   const [expiration, setExpiration] = useState<Date>(maxDate);
   const [geofilterFile, setGeofilterFile] = useState<File | null>(null);
   const [assetFilter, setAssetFilter] = useState("");  // Asset filter value
   const [assetFilterType, setAssetFilterType] = useState("startswith");  // Asset filter type
   const [assetOwners, setAssetOwners] = useState<Option[]>([]);  // Asset owners found within selected org's transactions
   const [ownerFilter, setOwnerFilter] = useState(assetOwners);  // User selected owners

   // Constants for tracking owner/area radio
   const noOwnerOrAreaFilter = "none";
   const ownerFilterSelected = "owner";
   const areaFilterSelected = "area";
   const [ownerOrAreaFilter, setOwnerOrAreaFilter] = useState(noOwnerOrAreaFilter);

   const multiSelectStringOverride = {
      "allItemsAreSelected": "All",
   }


   useEffect(() => {
      if (param.orgs) {
         let orgsWithDataSharing = param.orgs.filter(o => o.allow_datasharing);
         if (orgsWithDataSharing.length) {
            setOrgPageProperties(orgsWithDataSharing[0]);
         }
      }
      if (param.tokens) {
         if (param.tokens.length >= 10) {
            setTokenCountError("Data keys are limited to 10");
         } else {
            setTokenCountError("");
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [param.orgs, param.tokens]);

   const onGeofilterFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      setTokenGenerateError("")
      if (event.target.files) {
         setGeofilterFile(event.target.files[0])
      } else {
         setGeofilterFile(null)
      }
   }

   const onOwnerOrAreaFilterSelectionChange = (event: React.MouseEvent<HTMLInputElement>) => {
      let filter = event.currentTarget.value;
      if (ownerOrAreaFilter === noOwnerOrAreaFilter) {
         // No owner or area filter has been set yet
         setOwnerOrAreaFilter(filter);
      } else if (filter !== ownerOrAreaFilter) {
         // We are chaning form owner to area or area to owner
         setOwnerOrAreaFilter(filter);
         if (filter === areaFilterSelected) {
            setOwnerFilter(assetOwners);
         } else {
            clearFilterFile();
         }
      } else {
         // We are unselecting area or owner filter
         event.currentTarget.checked = false;
         setOwnerOrAreaFilter(noOwnerOrAreaFilter);
         clearFilterFile();
         setOwnerFilter(assetOwners);
      }
   }

   function clearFilterFile() {
      let input = document.getElementById('geofilterInput') as HTMLInputElement;
      if (input !== null) {
         input.value = "";
         input.files = null;
      }
      setGeofilterFile(null);
      setTokenGenerateError("");
   }

   const onOrgChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setTokenGenerateError("");
      let orgId = e.target.value;
      let org = param.orgs?.find(o => o.id === orgId)!!;
      setOrgPageProperties(org);
   }

   function setOrgPageProperties(org: IOrg) {
      setOrganization(org.id);
      setOrgHasGeoFilter(org.allow_geofilter);
      clearFilterFile();
      setOwnerOrAreaFilter(noOwnerOrAreaFilter);
      infraMarkerService.getTransactionOwners(org.id, store.auth.access_token!!).then((resp) => {
         let items = resp?.data?.owners.map(i => {
            let desc = i.description === i.asset_owner ? i.description : i.description + '(' + i.asset_owner + ')';
            return { 'label': desc, 'value': i.asset_owner };
         });
         setAssetOwners(items);
         setOwnerFilter(items);
      });
   }

   const onTokenNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      let tokenName = event.target.value;
      setTokenName(tokenName);
      if (param.tokens.some(t=>t.name === tokenName)) {
         setTokenNameError("Key name already exists.");
      } else {
         setTokenNameError("");
      }
   }


   const onExpirationChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (new Date(event.target.value) < new Date(event.target.max)) {
         setExpiration(new Date(event.target.value));
      } else {
         setExpiration(maxDate);
      }
   }

   const onAssetFilterTextChange = (event: ChangeEvent<HTMLInputElement>) => {
      setAssetFilter(event.target.value);
   }

   const onAssetFilterTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setAssetFilterType(e.target.value);
   }



   function generateToken() {
      let hasAllSelected = assetOwners.every((e, i) => ownerFilter[i] != null && e.value === ownerFilter[i].value);
      console.log(hasAllSelected);
      let owners = hasAllSelected ? [] : ownerFilter.map(i => i.value);
      console.log(owners);
      InfraMarkerService.generateDwToken(organization, tokenName, expiration, geofilterFile, store.auth.access_token!!, owners, assetFilterType, assetFilter)
         .then((response) => {
            param.setLoading(false);
            if (response.status === 201) {
               let formatted = expiration.toISOString().split('T')[0] as unknown as Date;
               let newToken = {
                  id: response.data.id,
                  token: response.data.token,
                  name: tokenName,
                  org_id: organization,
                  expiration_date: formatted,
                  geofilter: undefined,
                  geofilter_name: response.data.geofilter_name,
                  asset_filter: assetFilter ? assetFilterType + " '" + assetFilter + "'" : "",
                  owner_filter: owners ? owners.join(',') : "",
                  created_by: param.userName,
                  checked: true,
                  cloud_geofilter_url: response.data.cloud_geofilter_url,
                  is_geofilter_approved: response.data.is_geofilter_approved
               } as IDwToken;
               param.addToken(newToken);
               (document.getElementById('tokenNameInput') as HTMLInputElement).value = "";
               clearOwnerOrAreaRadioSelection();
               setOwnerOrAreaFilter(noOwnerOrAreaFilter);
               setOwnerFilter(assetOwners);
               setAssetFilter("");
               setTokenName("");
               clearFilterFile();
               // Select the new token after the screen renders the list
               setTimeout(() => {
                  (document.getElementById(response.data.token + '-radio') as HTMLInputElement).click();
               }, 250);
            }
         })
         .catch((error) => {
            param.setLoading(false);
            clearFilterFile();
            setTokenGenerateError(error.response.data[0].replace(/['"]+/g, '').replace(/[[\]']+/g, ''));
         });
   }

   function clearOwnerOrAreaRadioSelection() {
      let ownerRb = document.getElementById('ownerRadioInput') as HTMLInputElement
      if (ownerRb) {
         ownerRb.checked = false;
      }

      let areaRb = document.getElementById('areaRadioInput') as HTMLInputElement
      if (areaRb) {
         areaRb.checked = false;
      }
   }

   return (
      <>
         <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4'>
            <h3 className="org-section-header">Generate data access key</h3>
            <p>
               Generating a data access key will allow you to build a link that securely allows you to access geospatial data until the expiration date selected.
               {
                  orgHasGeoFilter ?
                     <span>  You may optionally upload a file containing polygons to filter the data by the defined area instead of filtering by your organization.</span>
                     : <></>
               }
            </p>
            <div className='row data-parameters'>
               <div className="col-md-12">
                  Key name <span className='required-text' title='required'>*</span><i className="bi-info-circle tt keyname-tooltip" role="img"></i>
                  <Tooltip anchorSelect=".keyname-tooltip">
                     Description of the key.  Must be unique.
                  </Tooltip>
                  <input id='tokenNameInput' className='form-control' type='text' onChange={onTokenNameChange} aria-label='Description of the key' />
               </div>
               {
                  param.orgs?.length && param.orgs?.length > 1 ?
                     <div className="col-md-12">
                        Organization <span className='required-text' title='required'>*</span><i className="bi-info-circle tt org-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".org-tooltip">
                           Organization this data access key will be associated with.
                        </Tooltip>
                        <select className="form-control" onChange={onOrgChange} aria-label='Organization'>
                           {param.orgs?.filter(o => o.allow_datasharing).map((org, i) => <option value={org.id} key={org.id}>{org.name}</option>)}
                        </select>
                     </div> : <></>
               }
               <div className="col-md-12">
                  Expiration <span className='required-text' title='required'>*</span> <i className="bi-info-circle tt expiration-tooltip" role="img"></i>
                  <Tooltip anchorSelect=".expiration-tooltip">
                     The date that the data access key will expire and will no longer<br />
                     be usable.  For security purposes, this date can be set to a maximum<br />
                     of 6 months from today but can be set to sooner if desired.
                  </Tooltip>
                  <input
                     className="form-control"
                     type="date"
                     value={expiration.toISOString().split('T')[0]}
                     max={maxDate.toISOString().split('T')[0]}
                     onChange={onExpirationChange}
                     aria-label='Expiration date' />
               </div>

               <div className="col-md-12">
                  Asset filter <i className="bi-info-circle tt asset-filter-tooltip" role="img"></i>
                  <Tooltip anchorSelect=".asset-filter-tooltip">
                     Optional text filter applied to the Asset name property.<br />
                  </Tooltip>
                  <div className='form-row'>
                     <div className='col-md-5'>
                        <select id='assetFilterTypeSelect' value={assetFilterType} className='form-control' aria-label='Asset filter' onChange={onAssetFilterTypeChange} >
                           <option value="startswith">Starts with</option>
                           <option value="endswith">Ends with</option>
                           <option value="contains">Contains</option>
                           <option value="equals">Equals</option>
                        </select>
                     </div>
                     <div className='col-md-7'>
                        <input
                           className="form-control"
                           type="text"
                           onChange={onAssetFilterTextChange}
                           value={assetFilter}
                           aria-label='Asset filter' />
                     </div>
                  </div>
               </div>

               {orgHasGeoFilter ? <div className="col-md-12">You may optionally filter by either Owner or Area</div> : <></>}

               <div className="col-md-12 form-row">
                  {orgHasGeoFilter ?
                     <div className='col-md-1'>
                        <input id="ownerRadioInput" type="radio" name="ownerOrArea" aria-labelledby='ownerFilterLabel' value={ownerFilterSelected} style={{ height: '100%' }} onClick={onOwnerOrAreaFilterSelectionChange} />
                     </div> : <></>
                  }
                  <div className={orgHasGeoFilter ? 'col-md-11' : 'col-md-12'}>
                     <span id='ownerFilterLabel'>Owner filter <i className="bi-info-circle tt owner-filter-tooltip" role="img"></i></span>
                     <Tooltip anchorSelect=".owner-filter-tooltip">
                        Optional text filter applied to the Asset Owner property.<br />
                     </Tooltip>
                     <MultiSelect
                        labelledBy='ownerFilterLabel'
                        className='form-control'
                        onChange={setOwnerFilter}
                        value={ownerFilter}
                        options={assetOwners}
                        hasSelectAll={true}
                        overrideStrings={multiSelectStringOverride}
                        disabled={ownerOrAreaFilter !== ownerFilterSelected && orgHasGeoFilter} />
                  </div>
               </div>

               {orgHasGeoFilter ?
                  <div className="col-md-12 form-row">
                     <div className='col-md-1'>
                        <input id='areaRadioInput' aria-label='Area filter radio' type="radio" name="ownerOrArea" value={areaFilterSelected} style={{ height: '100%' }} onClick={onOwnerOrAreaFilterSelectionChange} />
                     </div>
                     <div className='col-md-11'>
                        Area filter<i className="bi-info-circle tt area-filter-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".area-filter-tooltip">
                           Optional polygon file to use for filtering.<br />
                           This file must be in GeoJSON format.
                        </Tooltip>
                        <br />
                        {ownerOrAreaFilter === areaFilterSelected ?
                           <div className='alert-warning'>Area filter files need Berntsen's approval before sharing. Upload the file and click "generate" to start the approval process.  A confirmation will be sent to your email address once the review is complete.</div>
                           : <></>
                        }
                        <input id='geofilterInput' type='file' accept='.geojson' onChange={onGeofilterFileChange} aria-label='Area filter input file' disabled={ownerOrAreaFilter !== areaFilterSelected} />
                        {tokenGenerateError ? <div className='col-md-12 alert alert-danger'>{tokenGenerateError}</div> : <></>}
                     </div>
                  </div> : <></>
               }

               <div className="col-md-12" style={{ paddingTop: '5px' }}>
                  {tokenNameError ? <div className='col-md-12 alert alert-danger'>{tokenNameError}</div> : <></>}
                  {tokenCountError ? <div className='col-md-12 alert alert-danger'>{tokenCountError}</div> : <></>}
                  <button disabled={!organization || !expiration || !tokenName || tokenNameError!=="" || tokenCountError !==""} onClick={generateToken} className="btn btn-primary">
                     <i className="bi-key-fill" role="img"></i> Generate
                  </button>
               </div>
            </div>
            <br />
         </div>
      </>
   );
}
