import { combineReducers } from 'redux';
import errors from './errors';
import messages from './messages';
import auth, { IAuthState } from './auth';
import orgs, { IOrgState } from './organizations';

export interface IAppState{
   errors: any;
   messages: any;
   auth: IAuthState;
   org: IOrgState;
}

export default combineReducers({  
  errors,
  messages,
  auth,
  org: orgs,
});