/*

This is a simple React Component to display a logged-in user's organizations in a list.
The organizations will display the license, usage, and have a link to view most recent
transactions.

*/

import React, { Component } from "react";
import License from "./License";
import Usage from "./Usage";
import Transactions from "./Transactions";
import { connect } from 'react-redux';

class Organization extends Component {


   render() {
      return (
         <div>
            <h2 className="page-title">{this.props.name} ({this.props.owner})</h2>
            <div className="row">
               <License org_id={this.props.id} has_data_sharing={this.props.has_data_sharing} />
               <Usage org_id={this.props.id} has_data_sharing={this.props.has_data_sharing} />
            </div>
            <Transactions org_id={this.props.id} />
         </div>
      )
   }
}


function mapStateToProps(state) {
   return {
      auth: state.auth
      
   }
};

export default connect(mapStateToProps)(Organization);

