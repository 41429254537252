export const ActionTypes = {
   ORGS_LOADING: 'ORGS_LOADING',
   ORGS_LOADED: 'ORGS_LOADED',
   CLEAR_ORGS: 'CLEAR_ORGS',
   GET_ERRORS: 'GET_ERRORS',
   CREATE_MESSAGE: 'CREATE_MESSAGE',
   USER_LOADING: 'USER_LOADING',
   USER_LOADED: 'USER_LOADED',
   AUTH_ERROR: 'AUTH_ERROR',
   LOGIN_SUCCESS: 'LOGIN_SUCCESS',
   LOGIN_FAIL: 'LOGIN_FAIL',
   LOGOUT_SUCCESS: 'LOGOUT_SUCCESS'
}

export interface IAction {
   type: string,
   payload: any
}