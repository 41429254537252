import { ActionTypes, IAction } from '../actions/types';

export interface IUser {
   email: String;
   force_portal_password_reset: boolean;
   url: string;
   username: string
}

export interface IAuthState {
   access_token: string | null;
   refresh_token: string | null;
   isAuthenticated: boolean | null;
   isLoading: boolean;
   user: IUser | null;
}

const initialState: IAuthState = {
   access_token: localStorage.getItem('access_token'),
   refresh_token: localStorage.getItem('refresh_token'),
   isAuthenticated: null,
   isLoading: false,
   user: null,
};

export default function auth(state = initialState, action: IAction): IAuthState {
   switch (action.type) {
      case ActionTypes.USER_LOADING:
         return {
            ...state,
            isLoading: true,
         };
      case ActionTypes.USER_LOADED:
         return {
            ...state,
            isAuthenticated: true,
            isLoading: false,
            user: action.payload
         };
      case ActionTypes.LOGIN_SUCCESS:
         localStorage.setItem('access_token', action.payload.access_token);
         localStorage.setItem('refresh_token', action.payload.refresh_token);
         return {
            ...state,
            ...action.payload,
            isAuthenticated: true,
            isLoading: false,
         };
      case ActionTypes.AUTH_ERROR:
      case ActionTypes.LOGIN_FAIL:
      case ActionTypes.LOGOUT_SUCCESS:
         localStorage.removeItem('access_token');
         localStorage.removeItem('refresh_token');
         return {
            ...state,
            access_token: null,
            refresh_token: null,
            user: null,
            isAuthenticated: false,
            isLoading: false,
         };
      default:
         return state;
   }
}