import { ActionTypes, IAction } from '../actions/types';

const initialState = {
   msg: {},
   status: null,
};

export default function errors(state = initialState, action: IAction) {
   switch (action.type) {
      case ActionTypes.GET_ERRORS:
         return {
            msg: action.payload.msg,
            status: action.payload.status,
         };
      default:
         return state;
   }
}