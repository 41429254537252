import axios from "axios";
import { tokenConfig } from "./auth";
import { ActionTypes } from "./types";
import ENV_CONFIG from "../config";

export const loadOrganizations = () => (dispatch: any, getState: any) => {
   dispatch({ type: ActionTypes.ORGS_LOADING });
   const config = tokenConfig(getState);
   if (config.headers.Authorization) {
      axios
         .get(ENV_CONFIG.BASE_API_URL + '/api/v1/organizations/', tokenConfig(getState))
         .then((res) => {
            dispatch({
               type: ActionTypes.ORGS_LOADED,
               payload: res.data.results,
            });
         })
   }
};