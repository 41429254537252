import { useParams } from "react-router-dom";
import Loader from "../common/Loader";
import InfraMarkerService from '../../api/infraMarkerService';
import React, { useEffect, useState } from "react";
import ENV_CONFIG from "../../config";

export default function Acknowledgement() {
   const [loading, setLoading] = useState(true);
   const [message, setMessage] = useState("");
   const [url, setUrl] = useState<string | null>(null);
   const [termsUrl, setTermsUrl] = useState<string | null>(null);
   const { shareId } = useParams();  
   const customerTermsUrl = ENV_CONFIG.BASE_API_URL + '/static/InfraMarker_Data_Recipient_Sharing_Agreement.pdf'

   useEffect(() => {
      if (shareId) {
         InfraMarkerService.acknowledgeDataShare(shareId!)
            .then((response) => {
               setUrl(response.data.url);
               setTermsUrl(customerTermsUrl);
               setLoading(false);
            })
            .catch(() => {setLoading(false); setMessage("Your share request is no longer available.");});
      }
   }, [customerTermsUrl, shareId]);

   return (
      loading ?
         <Loader message='Loading...' /> :
         <div className="main-content">
            <br />
            {termsUrl ? 
               <h4 className="text-center">
                  You have agreed to the <a href={termsUrl} style={{textDecoration:"underline", fontWeight:"bolder"}} target="blank">Recipient Terms of Use</a>.  You may now use your Data Link.
               </h4> :
               <h3 className="text-center">{message}</h3>
            }
            <br />
            {url ?
               <>
                  <h5 className="text-center">This link should not be called more often than once every 5 minutes</h5>
                  <br />
                  <h5 className="text-center">Data Link: <a style={{textDecoration:"underline", fontWeight:"bolder"}} href={url}>{url}</a></h5>
               </>: <></>}
         </div>
   )
}