import React, { Component } from "react";
import { connect } from 'react-redux';
import { Tooltip as RTooltip } from 'react-tooltip';
import axios from "axios";
import ENV_CONFIG from "../../config";
import { getAuthHeaders } from '../../common';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Moment from "moment";

class Usage extends Component {

   state = {
      usage: {}
   };

   componentDidMount() {

      // request usage
      axios
         .get(
            ENV_CONFIG.BASE_API_URL + '/api/v1/usage/?org_id=' + this.props.org_id, getAuthHeaders(this.props.auth.access_token))
         .then(
            (response) => {
               this.setState({ usage: response.data });
            })
         .catch((error) => {
            this.setState({ usage: null });
         }
         );

   }

   render() {
      return (
         <>
            {this.props.has_data_sharing ?
            <div className="col-12 col-md-6">
               <h3 className="org-section-header">Data Sharing</h3>
               {this.state.usage ?
                  <p style={{paddingLeft:"10px"}}>
                     Pending shares: {this.state.usage.pending_share_count}<br />
                     Active shares: {this.state.usage.active_share_count}<br />
                     {this.state.usage.last_share_execution ?
                        <span>Last share execution: {Moment(this.state.usage.last_share_execution).local().format("MM/DD/YYYY hh:mm:ss a")}<br /></span>
                        : <></>}
                     {this.state.usage.last_share_record_count === null ? <></> :
                        <span>Last share records returned: {this.state.usage.last_share_record_count}</span> }
                  </p> : <></>}
            </div> : <></>}
            <div className="col-12">
               <h3 className="org-section-header">Usage <i className="bi-info-circle small usage-tooltip" role="img"></i></h3>
               <RTooltip anchorSelect=".usage-tooltip">
                  Includes transactions for the prior year.
               </RTooltip>
               {(null == this.state.usage) ? <p>No usage found.</p> :
                  <ResponsiveContainer className="content-body" width="100%" height={200}>
                     <BarChart
                        data={this.state.usage.usage}
                        margin={{
                           top: 5,
                           right: 50,
                           left: 20,
                           bottom: 5,
                        }}
                        barSize={20}
                     >
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="transactions" fill="var(--berntsen-dark-blue)" background={{ fill: '#eee' }} />
                        <XAxis dataKey="date" scale="point" padding={{ left: 10, right: 10 }} />
                     </BarChart>
                  </ResponsiveContainer>
               }
            </div>
         </>
      )
   }
}

function mapStateToProps(state) {
   return {
      auth: state.auth
   }
};

export default connect(mapStateToProps)(Usage);
