import React, { useState, useRef } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth';
import ReCAPTCHA from "react-google-recaptcha"
import ENV_CONFIG from '../../config';

function Login({ auth, errors, login }) {
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [captchaToken, setCaptchaToken] = useState(null);
   const captchaRef = useRef(null);
   function onRecaptcha(value) {
      setCaptchaToken(value);
   }

   const onSubmit = (e) => {
      e.preventDefault();
      captchaRef.current.reset();
      let token = captchaToken;
      setCaptchaToken(null);
      login(username, password, token);
   };

   let landing = "/"
   if (auth.isAuthenticated && auth.user != null && localStorage.getItem('login_redirect')) {
      landing = localStorage.getItem('login_redirect');
      localStorage.removeItem('login_redirect');
   }

   const onUsernameChange = (e) => setUsername(e.target.value);

   const onPasswordChange = (e) => setPassword(e.target.value);

   return (
      auth.isAuthenticated && auth.user != null ?
         <Navigate to={landing} /> :
         <div className='login'>
            <div className="col-md-6 m-auto">
               <div className="card card-body mt-5">
                  <h2 className="text-center">Login</h2>
                  <form onSubmit={onSubmit}>
                     <div className="form-group">
                        <label htmlFor="username-input">Username</label>
                        <input
                           id="username-input"
                           type="text"
                           className="form-control"
                           name="username"
                           onChange={onUsernameChange}
                           value={username} />
                     </div>

                     <div className="form-group">
                        <label htmlFor="password-input">Password</label>
                        <input
                           id="password-input"
                           type="password"
                           className="form-control"
                           name="password"
                           onChange={onPasswordChange}
                           value={password} />
                     </div>

                     <div className="form-group">
                        <ReCAPTCHA sitekey={ENV_CONFIG.RECAPTCHA_SITE_KEY} ref={captchaRef} onChange={onRecaptcha} />
                        <br />
                        <button type="submit" title='Login button' disabled={captchaToken === null} className="btn btn-primary">
                           Login
                        </button>
                     </div>
                     {errors.msg ? <div className="required-text">{errors.msg.error_description}</div> : <div></div>}
                     <p>
                        Don't have an account? <Link to='/contact-us' className='link'>Contact us</Link>
                     </p>
                     <p>
                        Forgot your password? <Link to='/reset-password' className='link'>Click here</Link>
                     </p>
                  </form>
               </div>
            </div>
         </div>
   );
}

const mapStateToProps = (state) => ({
   auth: state.auth,
   errors: state.errors
})

export default connect(mapStateToProps, { login })(Login);