import { ActionTypes } from '../actions/types';

const initialState = {};

export default function messages(state = initialState, action: any) {
   switch (action.type) {
      case ActionTypes.CREATE_MESSAGE:
         return (state = action.payload);
      default:
         return state;
   }
}