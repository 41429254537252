import React from 'react';

export default function Footer() {
   return (
      <footer className='page-footer footer row'>
            <div className='col-3'>
               <a href='https://www.inframarker.com/privacy-statement' target='blank'>Privacy</a>
            </div>
            <div className='col-6'>
               InfraMarker is a registered trademark of Berntsen International, Inc.<br />
               © Copyright 2024 by Berntsen International, Inc.
            </div>
            <div className='col-3'>
               <a href='https://www.inframarker.com/terms-of-use' target='blank'>Terms of Use</a>
            </div>
      </footer>
   );
}
