import React, { Component, Fragment } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Dashboard from './dashboard/Dashboard';
import Login from './accounts/Login';
import PrivateRoute from './common/PrivateRoute';
import { Provider } from 'react-redux';
import store from '../store';
import { loadUser } from '../actions/auth';
import ChangePassword from './accounts/ChangePassword';
import ResetPassword from './accounts/ResetPassword';
import ContactUs from './support/ContactUs';
import Acknowledgement from './dataServices/Acknowledgement';
import GeoServices from './dataServices/GeoServices';


export default class App extends Component {
   componentDidMount() {
      store.dispatch(loadUser());
   }

   render() {
      return (
         <Provider store={store}>
            <Router>
               <Fragment>
                  <div className='wrapper'>
                     <Header />
                     <div className="container page-body">
                        <Routes>
                           <Route exact path='/' element={<PrivateRoute />} >
                              <Route exact path='/' element={<Dashboard />} />
                              <Route exact path='/data-services' element={<GeoServices />} />
                           </Route>
                           {/* Authenticated user changing password, outside of PrivateRoute to allow for force password redirection. */}
                           <Route exact path='/change-password' element={<ChangePassword />} />
                           <Route exact path="/login" element={<Login />} />
                           {/* Email link to change password for unauthenticated user */}
                           <Route path='/change-password/:userId/:token' element={<ChangePassword />} />
                           {/* Redirect from forgot password screen */}
                           <Route path='/forgot-password/:email/:partialToken' element={<ChangePassword />} />
                           {/* Forgot password screen */}
                           <Route path='/reset-password' element={<ResetPassword />} />
                           {/* Contact us screen */}
                           <Route path='/contact-us' element={<ContactUs />} />
                           {/* Data share acknowledgement */}
                           <Route path='/acknowledge/:shareId' element={<Acknowledgement />} />
                        </Routes>
                     </div>
                     <Footer  />
                  </div>
               </Fragment>
            </Router>
            {/*</AlertProvider>*/}
         </Provider>
      );
   }
}
