
export function getAuthHeaders(access_token?: string){
  
    // Headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': '',
      },
    };
  
    // If token, add to headers config
    if (access_token) {
      config.headers['Authorization'] = `Bearer ${access_token}`;
    }
  
    return config;
  }
  