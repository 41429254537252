import React from 'react';

function Loader(props: any) {
   return (
      <div className="center">
         <strong>{props.message}</strong>
         &nbsp;
         <span className="loader spinner-border" role="status">
            <span className="sr-only"></span>
         </span>
      </div>
   );
}

export default Loader;