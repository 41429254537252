import { ActionTypes } from './types';

// CREATE MESSAGE
export const createMessage = (msg: any) => {
  return {
    type: ActionTypes.CREATE_MESSAGE,
    payload: msg,
  };
};

// RETURN ERRORS
export const returnErrors = (msg: any, status: any) => {
  return {
    type: ActionTypes.GET_ERRORS,
    payload: { msg, status },
  };
};