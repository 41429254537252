import React, { FC, useState } from 'react';
import { logout } from '../../actions/auth';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import InfraMarkerService from '../../api/infraMarkerService';
import { IAppState } from '../../reducers';
import { IAuthState } from '../../reducers/auth';


interface IContactUsProps {
   auth: IAuthState;
 }

const ContactUs: FC<IContactUsProps> = ({ auth }) => {

   const [username, setUsername] = useState("");
   const [email, setEmail] = useState("");
   const [supportText, setSupportText] = useState("");
   const [showSuccess, setShowSuccess] = useState(false);
   const [showFailure, setShowFailure] = useState(false);

   const navigate = useNavigate();

   const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(event.target.value);
   }

   const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
   }

   const onSupportTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setSupportText(event.target.value);
   }

   function submitContactUs(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
      
      InfraMarkerService.submitContactUs(email, username, auth.access_token, supportText)
         .then(() => {
            setShowSuccess(true);
            setTimeout(() => {
               navigate('/');
            } , 2500);
         })
         .catch(() => setShowFailure(true));
   }

   return (
      <div className='main-content'>
         <h2 className="page-title">Contact Us</h2>
         <h5>Please fill in the details below and submit.  We will get back to you as quickly as possible.</h5>
         <h5>To talk to someone directly, please contact us between the hours of 8:00 AM and 4:30 PM Monday through Friday (Central Standard Time) at 877-686-8550.</h5>
         <form aria-label="form" onSubmit={submitContactUs}>
         { (auth.access_token === null) ?
         <>
            <div className="form-group form-inline">
               <label htmlFor='username'>
                  Please enter your name: <span title='required' className="required-text">&nbsp;*</span>
               </label>
               &nbsp;
               <input
                  className="form-control"
                  type="input"
                  id='username'
                  name="username"
                  onChange={onUsernameChange}
                  value={username}
                  required
               />
            </div>
            <div className="form-group form-inline">
               <label htmlFor='email'>
                  Please enter an email address we can contact you at: <span title='required' className="required-text">&nbsp;*</span>
               </label>
               &nbsp;
               <input
                  className="form-control"
                  type="email"
                  id='email'
                  name="email"
                  onChange={onEmailChange}
                  value={email}
                  required
               />
            </div>
            </>
            : <br />}
            <div className="form-group">
               <label htmlFor='supportText'>
                  Please describe what we can help you with - include your phone number if you would like us to call:
                  <span title='required' className="required-text">&nbsp;*</span>
               </label>
               <textarea
                  cols={100}
                  rows={10}
                  className="form-control"
                  id='supportText'
                  name="supportText"
                  onChange={onSupportTextChange}
                  value={supportText}
                  required
               />
            </div>
            <div className="form-group">
               {!showSuccess ?
                  <>
                     <button type="submit" className="btn btn-primary">
                        Submit
                     </button>
                     &nbsp;
                     <Link className="btn btn-secondary" to='/'>Cancel</Link> 
                  </> : 
                  <div className='alert alert-success'>Your request was submitted.  Thank you and we will contact you as quickly as possible.</div>}
               {showFailure ?
                  <div className='alert alert-success'>There was a problem submitting the form.  Please try again or call us at 877-686-8550 or email directly to support@inframarker.com.</div>
                  :<></>
               }
            </div>
         </form>
      </div>
   );
}

const mapStateToProps = (state: IAppState) => ({
   auth: state.auth,
});

export default connect(mapStateToProps, { logout })(ContactUs);