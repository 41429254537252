import { ILicense } from "../api/models";
import Moment from "moment";

export class License {
   constructor(license: ILicense) {
      this.license = license;
   }

   private license: ILicense;

   getCurrentStartDateDisplay(): string {
      return this.license.current_start_date ? Moment.utc(this.license.current_start_date).format("MM-DD-YYYY"): "";
   }

   getCurrentEndDateDisplay(): string {
      return this.license.current_end_date ? Moment.utc(this.license.current_end_date).format("MM-DD-YYYY"): "";
   }

   getExpiringMessages(): string[] {
      const messages = [];
      const expirationDate = new Date(this.license.expiration_date);
      const todaysDate = new Date();
      todaysDate.setHours(0, 0, 0, 0);
      const today = todaysDate.getTime();
      const diffDays: number = Math.ceil((expirationDate.getTime() - today) / (1000 * 60 * 60 * 24)); 
      // if days < 0,  license is expired
      if (diffDays <= 0) {
         messages.push('Your license has expired.');
      } else if (diffDays < 60) {
         // if days < 60, license is expiring
         messages.push('Your license is expiring on ' + this.getCurrentEndDateDisplay() + '.');
      }
      
      if (this.license.current_start_date) {
         // If the expiration date beyond the current license date, the organization
         // already renewed and we shouldnt need these checks.
         const licEnd = new Date(this.license.current_end_date);
         
         if (this.license.current_end_date === this.license.expiration_date) {
            if (this.license.remaining_transaction_count <= 0) {
               messages.push('All of the transactions on your current license have been used.');
            } else if (this.license.remaining_transaction_count !== this.license.total_transaction_count) {
               const licStart = new Date(this.license.current_start_date);      
               const daysIntoLicense = Math.ceil((today - licStart.getTime()) / (1000 * 60 * 60 * 24));
               const burnRatePerDay = Math.ceil(100 * ((this.license.total_transaction_count - this.license.remaining_transaction_count) / daysIntoLicense)) / 100;
               const daysRemaining = Math.ceil((licEnd.getTime() - today) / (1000 * 60 * 60 * 24));
               const transactionDaysRemaining = Math.ceil(this.license.remaining_transaction_count / burnRatePerDay);
               if (transactionDaysRemaining < daysRemaining) {
                  const daysShort = daysRemaining - transactionDaysRemaining;
                  messages.push('Based on your use of ' + burnRatePerDay + ' per day, your transactions will be exhausted ' + daysShort + ' days before your license expires.')
               }
            }
         }
      }

      return messages;
   }
}