
// These configurations correspond to values injected during helm upgrade deployments.
// They are defined in the frontend's gitlab ci/cd variables, and pass through helm and
// are replaced here upon deployment
// https://create-react-app.dev/docs/adding-custom-environment-variables/
// https://docs.gitlab.com/ee/ci/variables/#via-the-ui


const ENV_CONFIG = {
   // Base API to be used for backend rest API calls
   BASE_API_URL : process.env.REACT_APP_BASE_API_URL,
   // Client id of the Application set up in Django OAuth that we are authorizing to
   API_CLIENT_ID : process.env.REACT_APP_API_CLIENT_ID,
   // Site key designated for our site with Recaptcha services
   RECAPTCHA_SITE_KEY : process.env.REACT_APP_RECAPTCHA_SITE_KEY,
};

export default ENV_CONFIG;