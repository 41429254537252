import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import InfraMarkerService from '../../api/infraMarkerService';
import { IAppState } from '../../reducers';
import { IDwToken, ISharingRequest } from '../../api/models';
import ENV_CONFIG from '../../config';

interface IDataShareParams {
   geoToken: IDwToken | null,
   tokenDisplay: string,
   sharingRequestCount: number,
   userName: string | undefined,
   orgHasDataSharing: boolean,
   geoOrgFilterType: string,
   addSharingRequest: (newRec: ISharingRequest) => void,
   setGeoOrgFilterType: (geoOrgFilterType: string) => void
}

export const DataShare: React.FC<IDataShareParams> = (param: IDataShareParams) => {
   const today = new Date();
   today.setHours(0, 0, 0, 0);
   const store = useSelector<any, IAppState>(store => store);

   const [emailing, setEmailing] = useState(false);
   const baseGeoUrl = ENV_CONFIG.BASE_API_URL + '/api/v1/geo/transactions.geojson?token=';
   const customerTermsUrl = ENV_CONFIG.BASE_API_URL + '/static/InfraMarker_Data_Customer_Sharing_Agreement.pdf'
   const [geoLocationType, setGeoLocationType] = useState('asset');
   const [geoLatestOnly, setGeoLatestOnly] = useState(true);
   const [geoIncludeEmpty, setGeoIncludeEmpty] = useState(false);
   const [geoFromDate, setGeoFromDate] = useState<Date | null>(null);
   const [geoThroughDate, setGeoThroughDate] = useState<Date | null>(null);
   const [sharingDescription, setSharingDescription] = useState<string>('');
   const [sharingRecipient, setSharingRecipient] = useState<string>('');
   const [emailSuccess, setEmailSuccess] = useState<boolean | null>(null);
   const [emailFailure, setEmailFailure] = useState<boolean | null>(null);
   const [agreedToTerms, setAgreedToTerms] = useState(false);
   
   const onGeoLatestOnlyChange = (e: ChangeEvent<HTMLInputElement>) => {
      setGeoLatestOnly(e.target.checked);
   }

   const onGeoLocationTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setGeoLocationType(e.target.value);
   }

   const onGeoEmptyLocationsChange = (e: ChangeEvent<HTMLInputElement>) => {
      setGeoIncludeEmpty(e.target.checked);
   }

   const onGeoOrgFilterTypeChangeChange = (e: ChangeEvent<HTMLSelectElement>) => {
      param.setGeoOrgFilterType(e.target.value);
   }

   const onGeoFromDateChange = (event: ChangeEvent<HTMLInputElement>) => {
      let fromDate = event.target.value ? new Date(event.target.value) : null
      setGeoFromDate(fromDate)
   }

   const onGeoThroughDateChange = (event: ChangeEvent<HTMLInputElement>) => {
      let throughDate = event.target.value ? new Date(event.target.value) : null
      setGeoThroughDate(throughDate)
   }

   const onSharingDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSharingDescription(event.target.value);
   }

   const onSharingRecipientChange = (event: ChangeEvent<HTMLInputElement>) => {
      setEmailFailure(false);
      setSharingRecipient(event.target.value);
   }

   const onAgreedToTermsChange = (e: ChangeEvent<HTMLInputElement>) => {
      setAgreedToTerms(e.target.checked);
   }

   function hasFilterFileSelected(token: IDwToken | undefined | null): boolean {
      let orgHasLocation = param.orgHasDataSharing;
      return orgHasLocation && token?.geofilter !== null && token?.geofilter !== undefined;
   }

   const shareDataLink = (event: React.MouseEvent<HTMLElement>) => {
      setEmailFailure(false);
      setEmailing(true);
      event.preventDefault();
      let baseUrl = `${baseGeoUrl + param.geoToken?.token}`;

      let params = `&latest_only=${geoLatestOnly}&location_type=${geoLocationType}&empty_locations=${geoIncludeEmpty}`
      if (!hasFilterFileSelected(param.geoToken)) {
         params += `&org_filter_type=${param.geoOrgFilterType}`
      }
      if (geoFromDate !== null) {
         params += `&after=${geoFromDate.toISOString().split('T')[0] as unknown as Date}`
      }
      if (geoThroughDate !== null) {
         params += `&before=${geoThroughDate.toISOString().split('T')[0] as unknown as Date}`
      }

      let fullUrl = baseUrl + params;
      console.log(fullUrl);
      if (param.userName) {
         InfraMarkerService.createDataShare(param.geoToken?.token!!, sharingDescription!!, sharingRecipient!!, fullUrl, store.auth.access_token!!)
            .then((result) => {
               let newShare = result.data;
               param.addSharingRequest({
                  id: newShare.id,
                  token: param.geoToken?.id!,
                  description: sharingDescription!,
                  recipient: sharingRecipient!,
                  url: fullUrl,
                  acknowledged_on: null,
                  created_by: param.userName!,
                  created_on: newShare.created_on
               });
               setEmailing(false);
               setEmailSuccess(true);
               setTimeout(() => { setEmailSuccess(false); clearSharingInputs(); }, 3000)
            }).catch(() => {
               setEmailing(false);
               setEmailFailure(true);
            });
      }
   }

   function clearSharingInputs() {
      setSharingRecipient('');
      setSharingDescription('');
   }

   const validateEmail = (email: string): boolean => {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
   }

   return (
      <>
         <h3 className="org-section-header">Share data</h3>
         <p>Once you have generated at least one data access key above, adjust the parameters below to your preferences and click the '<i className="bi-envelope" role="img"></i> Share data' button to share a data link via email.  This link will allow the recipient to export feature data from our system and import it into their GIS system or application.  Select a different key under the 'Manage data access keys' section to share that key.</p>
         <form>
            <div className='row data-parameters'>
               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                        Data access key<i className="bi-info-circle tt share-key-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".share-key-tooltip">
                           Selected above in the "Manage..." section, this is the key to<br />
                           access the data service.  It will filter the features returned<br />
                           based on the organization it is associated to or based on the<br />
                           associated area filter file if present.
                        </Tooltip>
                     </div>
                     <div className='col-6'>
                        <span style={{ fontWeight: 'normal' }} title={param.geoToken?.token}>{param.tokenDisplay}</span>
                     </div>
                     <div className='col-1'>
                        <input id='justHereForHeight' className='form-control' style={{ visibility: 'hidden' }} aria-label='ignore' />
                     </div>
                  </div>
               </div>

               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                        Description <span className='required-text' title='required'>*</span> <i className="bi-info-circle tt share-desc-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".share-desc-tooltip">
                           Enter a description for the data you are sharing.
                        </Tooltip>
                     </div>
                     <div className='col-7'>
                        <input id='sharingDescription' value={sharingDescription} className='form-control' type='text' required onChange={onSharingDescriptionChange} aria-label='Description' />
                     </div>
                  </div>
               </div>

               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                        Location Type<i className="bi-info-circle tt share-locationtype-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".share-locationtype-tooltip">
                           The features returned can either contain asset locations (write or lock<br />
                           actions with location defined by host app) or device locations (all actions<br />
                           with location determined by the device running the RFID app).
                        </Tooltip>
                     </div>
                     <div className='col-7'>
                        <select className='form-control' onChange={onGeoLocationTypeChange} aria-label='Location type'>
                           <option value="asset">Asset</option>
                           <option value="device">Device</option>
                        </select>
                     </div>
                  </div>
               </div>

               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                        <label htmlFor='filterTypeSelect' >Filter Type<i className="bi-info-circle tt share-filtertype-tooltip" role="img"></i></label>
                        <Tooltip anchorSelect=".share-filtertype-tooltip">
                           When enabled this option allows you to either select transactions<br />
                           that your organization created (By org), or transactions that match<br />
                           your organization`s asset owner property (By asset).  This filter is<br />
                           not available if your key has an area filter file associated to it.
                        </Tooltip>
                     </div>
                     <div className='col-7'>
                        <select id='filterTypeSelect' className='form-control' onChange={onGeoOrgFilterTypeChangeChange} disabled={hasFilterFileSelected(param.geoToken)} >
                           {hasFilterFileSelected(param.geoToken) ? <option value="na">n/a</option> :
                              <>
                                 <option value="action">By org</option>
                                 <option value="owner">By asset</option>
                              </>
                           }
                        </select>
                     </div>
                  </div>
               </div>

               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                     <label htmlFor='fromDate'>From Date</label><i className="bi-info-circle tt share-fromdate-tooltip" role="img" title='Only return features after this date.'></i>
                        <Tooltip anchorSelect=".share-fromdate-tooltip">
                           Only return features after this date.
                        </Tooltip>
                     </div>
                     <div className='col-7'>
                        <input id='fromDate' className="form-control" type="date" onChange={onGeoFromDateChange} />
                     </div>
                  </div>
               </div>

               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                        <label htmlFor='throughDate'>Through Date</label><i className="bi-info-circle tt share-throughdate-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".share-throughdate-tooltip">
                           Only return features before this date.
                        </Tooltip>
                     </div>
                     <div className='col-7'>
                        <input id='throughDate' className='form-control' type="date" onChange={onGeoThroughDateChange} />
                     </div>
                  </div>
               </div>

               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                        Latest Only<i className="bi-info-circle tt share-latestonly-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".share-latestonly-tooltip">
                           Filter to the most recent transaction for each RFID tag.<br />
                           If From or Through dates are specified, the latest transaction<br />
                           within that timeframe for each RFID tag will be returned.
                        </Tooltip>
                     </div>
                     <div className='col-1'>
                        <input type='checkbox' checked={geoLatestOnly} onChange={onGeoLatestOnlyChange} aria-label='Latest only' />
                     </div>
                     <div className='col-6'>
                        <input id='justHereForHeight' className='form-control' style={{ visibility: 'hidden' }} aria-label='ignore' />
                     </div>
                  </div>
               </div>

               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                        Include Empty<i className="bi-info-circle tt share-empty-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".share-empty-tooltip">
                           Include features that do not have locations defined.
                        </Tooltip>
                     </div>
                     <div className='col-1'>
                        <input type='checkbox' checked={geoIncludeEmpty} onChange={onGeoEmptyLocationsChange} aria-label='Include empty locations' />
                     </div>
                     <div className='col-6'>
                        <input id='justHereForHeight' className='form-control' style={{ visibility: 'hidden' }} aria-label='ignore' />
                     </div>
                  </div>
               </div>

               <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                  <div className='row'>
                     <div className='col-5 text-right'>
                        Recipient Email <span className='required-text' title='required'>*</span> <i className="bi-info-circle tt share-recipient-tooltip" role="img"></i>
                        <Tooltip anchorSelect=".share-recipient-tooltip">
                           Enter a valid email address to receive the data link.
                        </Tooltip>
                     </div>
                     <div className='col-7'>
                        <input id='sharingRecipient' value={sharingRecipient} className='form-control' type='email' required onChange={onSharingRecipientChange} aria-label='Recipient email' />
                     </div>
                  </div>
               </div>

               <div className="col-12 text-center">
                  <br />
                  {!param.geoToken ? <div className='alert-danger share-validation'>A data access key must be selected.</div> : <></>}
                  {!sharingDescription ? <div className='alert-danger share-validation'>Description is required.</div> : <></>}
                  {!sharingRecipient ? <div className='alert-danger share-validation'>Recipient is required.</div> : <></>}
                  {!validateEmail(sharingRecipient) ? <div className='alert-danger share-validation'>Recipient must be a valid email address.</div> : <></>}
                  {param.sharingRequestCount >= 10 ? <div className='alert-danger share-validation'>Data sharing is limited to 10.</div> : <></>}
                  {!param.geoToken?.is_geofilter_approved ? <div className='alert-danger share-validation'>Geofilter file has not been approved yet.</div> : <></>}
               </div>

               <div className="col-12 text-center">
                  <br />
                  <label>
                     <input type='checkbox' checked={agreedToTerms} onChange={onAgreedToTermsChange}></input> &nbsp;
                     I have read and agree to the <a href={customerTermsUrl} target='blank' className='link'>Customer Terms of Use</a>
                  </label>
               </div>

               <div className="col-12 text-center">
                  <br />
                  {emailing || emailSuccess ? <></> :
                     <button type='submit' disabled={!param.geoToken || !sharingDescription || !sharingRecipient || param.sharingRequestCount >= 10 || !validateEmail(sharingRecipient) || !agreedToTerms || !param.geoToken.is_geofilter_approved} className="btn btn-primary" title="Click to email data link to entered recipient..." onClick={shareDataLink}>
                        <i className="bi-envelope" role="img"></i> Share data
                     </button>}
               </div>

               <div className="col-12 text-center">
                  {emailing && !emailSuccess ? <div><div className="spinner-border" role="status"><span className="sr-only"></span></div> Sharing...</div> : <></>}
                  {emailSuccess ? <div className='text-success'>Email sent.</div> : <></>}
                  {emailFailure ? <div className='text-danger'>Not able to send email.  Please make sure the recipient is a valid email address.</div> : <></>}
               </div>
            </div>
         </form>
      </>
   );
}
