import * as React from 'react';

function Dropdown (props: any) {
   const [open, setOpen] = React.useState(false);
 
   const handleOpen = () => {
     setOpen(!open);
   };
 
   return (
     <div className="dropdown">
       {React.cloneElement(props.trigger, {
         onClick: handleOpen,
       })}
       {open ? (
         <ul className="menu">
           {(props.menu as React.ReactElement[]).map((menuItem, index) => (
             <li key={index} className="menu-item">
               {React.cloneElement(menuItem, {
                 onClick: () => {
                   menuItem.props.onClick();
                   setOpen(false);
                 },
               })}
             </li>
           ))}
         </ul>
       ) : null}
     </div>
   );
 };

 export default Dropdown;