import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import logo from "../../images/InfraMarker_Logo_white.png";
import { NavLink } from 'react-router-dom'


export class Header extends Component {
   static propTypes = {
      auth: PropTypes.object.isRequired,
      orgState: PropTypes.object.isRequired,
      logout: PropTypes.func.isRequired,
   };


   render() {
      const { isAuthenticated, user } = this.props.auth;

      const authLinks = (
         <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            <li className="nav-item dropdown">
               <span className="nav-link dropdown-toggle navbar-text mr-3" data-toggle="dropdown">
                  {user ? `Welcome ${user.username}` : ''}
               </span>
               <div className="dropdown-menu">
                  <NavLink className={(navData) => (navData.isActive ? 'dropdown-item dropdown-active' : 'dropdown-item')} to="/">Home</NavLink>
                  <button onClick={this.props.logout} className="dropdown-item">
                     Logout
                  </button>
                  <NavLink className={(navData) => (navData.isActive ? 'dropdown-item dropdown-active' : 'dropdown-item')} to="/change-password">Change Password</NavLink>
                  <NavLink hidden={!this.props.orgState.orgs?.length} className={(navData) => (navData.isActive ? 'dropdown-item dropdown-active' : 'dropdown-item')} to="/data-services">Data Sharing</NavLink>
                  <NavLink className={(navData) => (navData.isActive ? 'dropdown-item dropdown-active' : 'dropdown-item')} to="/contact-us">Contact Us</NavLink>
               </div>
            </li>
         </ul>
      );

      const guestLinks = (
         <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
            {/* <li className="nav-item">
          <Link to="/login" className="nav-link">
            Login
          </Link>
        </li> */}
         </ul>
      );

      return (
         <>
            <nav className="navbar navbar-expand-sm">
               <div className="container">
                  <img className='logo' alt='InfraMarker by Berntsen' src={logo} />
                     <h1 className='site-heading'>
                        InfraMarker Customer Portal
                     </h1>
                  {isAuthenticated ? authLinks : guestLinks}
               </div>
            </nav>
         </>
      );
   }
}

const mapStateToProps = (state) => ({
   auth: state.auth,
   orgState: state.org
});

export default connect(mapStateToProps, { logout })(Header);