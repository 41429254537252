import { ActionTypes, IAction } from '../actions/types';

export interface IOrg {
   id: string;
   name: string;
   allow_geofilter: boolean;
   allow_datasharing: boolean;
}

export interface IOrgState {
   orgs: IOrg[] | null
   isLoading: Boolean
}

const initialState: IOrgState = {
   orgs: null,
   isLoading: false
}

export default function orgs(state = initialState, action: IAction): IOrgState {
   switch (action.type) {
      case ActionTypes.CLEAR_ORGS:
         return {
            ...state,
            orgs: null,
         };
      case ActionTypes.ORGS_LOADING:
         return {
            ...state,
            isLoading: true,
         };
      case ActionTypes.ORGS_LOADED:
         return {
            ...state,
            isLoading: false,
            orgs: action.payload
         };
      default:
         return state;
   }
}