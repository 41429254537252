import React, { useEffect } from "react";
import { useSortBy, useTable } from "react-table";
import { Tooltip } from 'react-tooltip';
import Moment from "moment";

export default function TransactionTable({ data, onSort, isAssets }) {

   const columns = React.useMemo(
      () => [
      {
         Header: "Asset Name",
         accessor: "asset_name",
         tooltip: "The human identifiable id or name defined by the owner of the asset.",
      },
      {
         Header: isAssets ? "Organization" : "Asset Owner",
         accessor: isAssets ? "org_name" : "asset_owner",
         tooltip: isAssets ?  "Organization that created the transaction." : "The owner of the asset. An example would be the name of the utility company or the name of the public works dept. of the city/town.",
      },
      {
         Header: "InfraMarker ID",
         accessor: "epc",
         tooltip: "Unique identifier for the InfraMarker RFID tag that was installed on the asset.",
      },
      {
         Header: "Action",
         accessor: "action",
         Cell: props =>  {
            return <span>{ props.value } {props.row.original.is_selected ? null :
               <i className="bi-dash-circle-fill icon-unselected" role="img" title="Not selected"></i> }</span>
         },
         tooltip: "The action performed.  Options are read (retrieve basic asset info from tag), write (write basic asset info to tag), and write-lock (write basic asset info to tag and prevent overwrite).",
      },
      {
         Header: "Date",
         accessor: "action_datetime",
         Cell: props =>  {
             return Moment(props.value)
              .local()
              .format("MM/DD/YYYY hh:mm:ss a")
         },
         tooltip: "The date/time the action occurred.",
      },
      {
         Header: "Latitude",
         accessor: "latitude",
         Cell: props =>  {
            return props.value ? Math.round(props.value * 100000000) / 100000000 : null;
         },
         tooltip: "Value in decimal degrees based on the asset's real world location.",
      },
      {
         Header: "Longitude",
         accessor: "longitude",
         Cell: props =>  {
            return props.value ? Math.round(props.value * 100000000) / 100000000 : null;
         },
         tooltip: "Value in decimal degrees based on the asset's real world location.",
      },
   ],
   [isAssets]);

   const initialSortBy = React.useMemo(
      () => [
         {
            id: "action_datetime",
            desc: true
         }
      ],
      []
   );

   // Use the useTable Hook to send the columns and data to build the table
   const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      rows, // rows for the table based on the data passed
      prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
      state: { sortBy },
   } = useTable(
      {
         columns,
         manualSortBy: true,
         initialState: { sortBy: initialSortBy },
         data,
      },
      useSortBy
   );

   useEffect(() => {
      onSort(sortBy);
   }, [onSort, sortBy]);

   return (
      <table className="table table-striped content-body" {...getTableProps()}>
         <thead>
            {headerGroups.map(headerGroup => (
               <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                     <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <span>
                           { column.isSorted ? column.isSortedDesc ? "▼ " : "▲ " : "" }
                        </span>
                        {column.render("Header")}
                        
                        <i className={`bi-info-circle ${column.id}-tooltip`} role="img"></i>
                        <Tooltip anchorSelect={`.${column.id}-tooltip`}>
                           {column.tooltip}
                        </Tooltip>
                     </th>
                  ))}
               </tr>
            ))}
         </thead>
         <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
               prepareRow(row);
               return (
                  <tr {...row.getRowProps()}>
                     {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                     })}
                  </tr>
               );
            })}
         </tbody>
      </table>
   );
}